.ratingNodeLayout {
  display: flex;
  flex-direction: column;
  flex: 3 1 0;
  gap: 12px
}

.rating {
  display: flex;
  flex: 1 1 auto;
}
.ratingLabelsColumn {
  width: 22px;
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  font-weight: 500;
}
.ratingLabels {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex: 1 1 0;
  transform: rotate(180deg);
}

.abstain {
  display: flex;
  flex: 0 1 100px;
}
.abstainLabelColumn {
  width: 22px;
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 4px 0;
}
.abstainLabel {
  transform: rotate(180deg);
}

.ratingNodeBorder {
  border: 5px solid black;
  display: flex;
  flex: 1 1 0;
  width: 100%;
}
.ratingNode {
  display: flex;
  flex: 1 1 0;
  position: relative;
}
