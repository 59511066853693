.cycleSelect {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;
}

.selectWithLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select {
  flex: 1;
}
