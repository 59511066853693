.ratingScaleEditor {
  display: flex;
  gap: 24px;
  padding: 0 24px;
}

.ratingNodeLayout {
  width: 300px;
  height: 600px;
  display: flex;
}

.ratingScaleFormColumn {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ratingScaleFormSection {
  display: flex;
  flex-direction: column;
}
.formControlGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.ratingScaleFormButton {
  align-self: flex-end;
}

.ratingScaleFieldGroup {
  display: flex;
  gap: 12px;
}
.ratingScaleLabelField {
  flex: 1 1 100px;
}
.ratingScaleMaxValueField {
  flex: 3 1 100px;
}

.sliderWithNumberInput {
  display: flex;
  gap: 8px;
  flex: 1 1 0;
  width: 100%;
  align-items: flex-start;
}
.sliderWithNumberInputSlider {
  flex: 5 1 300px;
  margin-bottom: 10px;
}
.sliderWithNumberInputNumberInput {
  flex: 1 1 100px;
}
