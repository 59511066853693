.node {
  line-height: 1.75;
  padding: 2px 4px;
  border-radius: 2px;
}
.node.hasClickHandler {
  cursor: pointer;
}
.node.readOnly {
  cursor: auto;
}
.node.disabled {
  cursor: auto;
  color: darkgray;
}
.node.selected {
  /* TODO: use color var (https://vms.atlassian.net/browse/VP3-2566) */
  background-color: rgba(186, 231, 255, 1);
}

.treeNodeChildren {
  margin-left: 20px;
}
