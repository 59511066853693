.descriptions {
  flex: 1 1 0;
  overflow-y: auto;
}

.subjectDescriptionTitle {
  display: flex;
  gap: 8px;
  align-items: center;
}
