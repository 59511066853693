.rating {
  flex: 1 1 0;
  display: flex;
  gap: 8px;
}

.unratedPoolColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 2 1 0;
  gap: 12px;
}
.unratedPool {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 8px;
}

.extra {
  align-self: flex-end;
}

.ratingNode {
  flex: 1 1 0;
}
