.debounceTextArea {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 500;
  font-size: 14px;
}
.input {
  flex: 1 1 0;
}

.syncIcon {
  color: lightgray;
}
.checkIcon {
  color: green;
}
