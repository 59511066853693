/* global override of ant design word break rules; #root is required to bump specificity */
#root .ant-table-thead > tr > th,
#root .ant-table-tbody > tr > td {
  word-break: normal;
  padding: 4px;
}

.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -5px;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px;
}

#root .ant-table-thead > tr > th > div > span {
  font-size: 12px;
}
