.pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.pagination button {
  padding: 4px 8px;
}
.pagination button:hover {
  cursor: pointer;
}
.pagination button:disabled {
  cursor: not-allowed;
}

.pagination select {
  padding: 4px 8px;
}

.pagination__transport {
  margin-right: 24px;
}
.pagination__transport :not(:last-child) {
  margin-right: 12px;
}
