.container {
  --link-icon-color: #1890ff;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: center;
}


.ratingInterfaceContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  gap: 24px;
}
.ratingInterface {
  display: flex;
  flex-direction: column;
  flex: 0 1 500px;
  min-width: 0px;
  gap: 8px;
}
.desktopRatingNotesContainer {
  display: none;
  flex-direction: column;
  flex: 0 1 500px;
}
.desktopRatingNotesContainerSpace {
  flex: 2 1 0;
}
.desktopRatingNotesTextArea {
  flex: 1 1 0;
  max-width: 500px;
}

.decisionLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.decisionLabelInner {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.contextLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.contextLabel h2 {
  margin-bottom: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contextLabel .infoIcon {
  color: var(--link-icon-color)
}

.ratingCanvasContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
}
.ratingCanvas {
  flex: 1 1 0;
  display: flex;
}

.ratableItem {
  position: relative;
  height: 54px;
  width: 60px;
  padding: 4px;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ratableItem > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ratableItemRating {
  visibility: visible;
}
.ratableItemRating.hidden {
  visibility: hidden;
}
.ratableItemRating.displayNone {
  display: none;
}

.ratableItemBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: -1;
}
.ratableItemBackgroundColor {
  background-color: var(--ratable-item-color, #ffffff);
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.controlIcon {
  color: var(--link-icon-color);
  font-size: 36px;
}

@media screen and (min-width: 900px) {
  .decisionLabel, .contextLabel {
    justify-content: flex-start;
  }
  .notesDrawerTrigger {
    display: none;
  }
  .desktopRatingNotesContainer {
    display: flex;
  }
}
