.ratableItem {
  position: relative;
  cursor: grab;
  text-align: center;
  outline: none;
  touch-action: none;
  user-select: none;
  max-width: min-content;
  max-height: min-content;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);
  z-index: calc(100 + var(--ratable-z-index, 0));
}

.ratableItem.hasRating {
  position: absolute;
  left: var(--position-left, 0);
  top: var(--position-top, 0);
}
.ratableItem.active {
  --active-blue: rgb(81, 203, 238);
  box-shadow: 0 0 5px var(--active-blue), 0 0 0 1px var(--active-blue);
  cursor: grabbing;
}
