.definitions {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 20ex auto;
}

.definitions dt {
  grid-column: 1;
}

.definitions dd {
  grid-column: 2;
}

.footnotes {
  font-size: small;
  font-style: italic;
}
