.criteria {
  display: flex;
  gap: 30px;
  height: 100%;
}

.criteria > * {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.selectedCriterion {
  display: flex;
  flex: 2;
  align-items: stretch;
  flex-direction: column;
}
.selectedCriterionDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: var(--background-blue);
}
.criteriaControls {
  align-self: flex-end;
}

