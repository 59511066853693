.risk-scale-table {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  text-align: center;
  margin-bottom: 12px;
  table-layout: fixed;
}

.risk-scale-table thead tr th {
  text-align: center;
  border: 1px solid #d9d9d9;
}

.risk-scale-table thead tr {
  border-bottom: 2px solid #d9d9d9;
  background-color: #F5F5F5;
}

.risk-scale-table thead tr th, .risk-scale-table tbody tr td {
  padding: 2px;
  border: 1px solid #d9d9d9;
}

.risk-scale-low {
  background-color: #bfb;
}

.risk-scale-ml {
  background-color: #ffb;
}

.risk-scale-high {
  background-color: #d5a2a5;
}

.risk-scale-table-item {
  width: 100px;
}
