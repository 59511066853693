table {
  width: 100%;
  background: #fff; }

table thead {
  border-bottom: 2px solid #d9d9d9; }

table thead tr th {
  text-align: left; }

table thead tr {
  background-color: #F5F5F5; }

table tr th {
  background-color: #F5F5F5; }

table thead tr th, table tbody tr td {
  padding: 8px; }

table tbody tr td .checkbox input[type=checkbox], table tbody tr td .checkbox-inline input[type=checkbox], table tbody tr td .radio input[type=radio], table tbody tr td .radio-inline input[type=radio] {
  position: relative;
  margin-left: 0; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-right: 1px solid #ddd; }

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  vertical-align: middle;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.table-pagination {
  margin: 5px 0; }
  .table-pagination_item {
    background: white;
    text-align: center;
    min-height: 28px;
    margin: 0 1px;
    padding: 5px; }

.table-row .table-edit-column {
  width: 84px;
  padding-bottom: 3px;
  padding-left: 12px; }
.table-row .table-icon {
  font-size: 1.3em;
  display: none;
  margin: 0 5px;
  cursor: pointer; }
  .table-row .table-icon:hover, .table-row .table-icon:focus {
    color: #3d75b2; }
.table-row .table-icon-fa {
  font-size: 1.6em;
  display: none;
  margin: 0 5px;
  cursor: pointer; }
  .table-row .table-icon-fa:hover, .table-row .table-icon-fa:focus {
    color: #3d75b2; }
.table-row:hover .table-icon, .table-row:hover .table-icon-fa, .table-row:focus .table-icon, .table-row:focus .table-icon-fa {
  display: inline-block; }

.table-cell-currency {
  text-align: right; }
.table-cell-number {
  text-align: right; }
.table-cell-percent {
  text-align: right; }
.table-cell-button {
  text-align: center; }
.table-cell-link {
  font-size: 1.2rem;
  text-align: center; }

.table-sort-glyph {
  font-size: 0.8em;
  left: 8px; }

.table-sort-icon {
  margin-left: 8px;
  font-size: 1.3em; }

.table-hover:hover .table-hover_show {
  opacity: 1; }
.table-hover_show {
  opacity: 0; }

table > tbody > tr > td.controls > div {
  visibility: hidden; }
table > tbody > tr:hover > td.controls > div {
  visibility: visible; }
table > tbody > tr > td.nowrap {
  white-space: nowrap; }
.impact-low {
  background-color: #bfb; }
  .impact-low-dark {
    background-color: #00bb00; }
.impact-ml {
  background-color: #ffb; }
  .impact-ml-dark {
    background-color: #ffd000; }
.impact-high {
  background-color: #d5a2a5; }
  .impact-high-dark {
    background-color: #df1e26; }

.alt-value-indices-table {
  padding-top: 20px; }
.schedule {
  background-color: #a2bed5; }

.performance {
  background-color: #bed5a2; }

.cost {
  background-color: #d5a2a5; }

.resource-function-matrix {
  table-layout: fixed;
  font-size: 1.2rem;
  border: none;
  border-collapse: separate;
  position: relative; }
  .resource-function-matrix thead {
    transform: translate(0px, 0px); }
  .resource-function-matrix > thead > tr > th {
    vertical-align: bottom;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #ddd; }
    .resource-function-matrix > thead > tr > th > .move-me {
      position: relative;
      height: 100%; }
  .resource-function-matrix tbody tr td input {
    border: solid 1px transparent;
    text-align: right;
    background: transparent;
    width: 100%; }
  .resource-function-matrix td:hover input {
    border: solid 1px #ddd; }

td.study-lock-toggle {
  text-align: center; }
  td.study-lock-toggle-can-update {
    cursor: pointer; }
  td.study-lock-toggle i {
    font-size: 1.3em; }
    td.study-lock-toggle i.fa-lock {
      font-size: 1.4em; }
    td.study-lock-toggle i.fa-unlock {
      color: #a2bed5; }

.td-checkbox {
  text-align: center; }

.item-picker .item-description {
  margin-top: 12px; }
